import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showMessageInfo(message,title)
  {
      this.toastr.info(message,title);
  }

  showMessage(message,title,isError=false)
  {
      if (isError)
        this.toastr.error(message,title);
      else 
        this.toastr.success(message, title)
  }

  
  showHTMLMessage(message, title,isError=false){
    if (isError)
    {
      
      this.toastr.error(message, title, {
        enableHtml :  true
      })
    }
    else
    {
      this.toastr.success(message, title, {
        enableHtml :  true
      })
    }
    
  }

  showMessageWithTimeout(message, title, timespan,isError=false){
    if (isError)
    {
      this.toastr.error(message, title ,{
        timeOut :  timespan
      })
    }
    else 
    {
      this.toastr.success(message, title ,{
        timeOut :  timespan
      })
    }    
  }

}